import * as React from 'react';

import Slider from 'react-slick';
import PropTypes from 'prop-types';

import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

import './gallery.scss';
import {GatsbyImage} from 'gatsby-plugin-image';

const Gallery = ({images}) => {

  const sliderOpts = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return <>
    <Slider {...sliderOpts}>
      {images.map((image, index) =>
        <Zoom overlayBgColorEnd="#3d3d3daa" key={index} className="slider-item">
          <div className="slider-item">
            <GatsbyImage alt={image.text} image={image?.image?.childImageSharp?.gatsbyImageData}/>
          </div>
        </Zoom>
      )}
    </Slider>
  </>;
};

Gallery.propTypes = {
  images: PropTypes.array.isRequired
};

export default Gallery;